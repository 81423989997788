import { EventEmitterTypes } from 'types/event-emitter.type';
import { EventEmitterHelpers } from 'helpers/event-emitter.helpers';
import ViewState from 'components/data/ViewState';
import { ScrollPosition } from '../components/canvas/types/types';
import { CANVAS_ZOOM_LEVEL, DEFAULT_ZOOM_LEVEL, MAX_ZOOM, MIN_ZOOM } from '../constants';
import { getTemplateData } from './data.helpers';
import Template from '../types/template.type';
import Format from '../types/format.type';

/**
 * Infinite viewer helpers.
 */
class InfiniteViewerHelpers {
    /**
     * Infinite Viewer holder.
     */
    private static infiniteViewer: any | null = null;

    /**
     * The zoom level that fits all the formats.
     */
    private static zoomLevelFit = 1;

    /**
     * Margin around the formats.
     */
    static MARGIN = 200;

    /**
     * Get the infinite viewer holder.
     * @returns Infinite viewer.
     */
    static getInfiniteViewer(): any | null {
        return InfiniteViewerHelpers.infiniteViewer;
    }

    /**
     * Set infinite viewer holder.
     * @param scene - Infinite viewer.
     */
    static setInfiniteViewer(infiniteViewer: any): void {
        InfiniteViewerHelpers.infiniteViewer = infiniteViewer;
    }

    /**
     * Get zoom level fit.
     * @returns Zoom level fit.
     */
    static getZoomLevelFit(): number {
        return InfiniteViewerHelpers.zoomLevelFit;
    }

    /**
     * Set zoom level fit.
     * @param zoomLevel - Zoom level.
     */
    static setZoomLevelFit(zoomLevel: number): void {
        InfiniteViewerHelpers.zoomLevelFit = zoomLevel;
    }

    /**
     * Get the container size.
     * @returns Container size.
     */
    static getContainerSize(): { width: number; height: number } {
        const infiniteViewer = InfiniteViewerHelpers.getInfiniteViewer();
        if (!infiniteViewer) return { width: 0, height: 0 };

        const container = infiniteViewer.containerElement.getBoundingClientRect();
        return { width: container.width, height: container.height };
    }

    /**
     * Get the scroll position.
     * @returns Scroll position.
     */
    static getScrollPosition(): ScrollPosition {
        const infiniteViewer = InfiniteViewerHelpers.getInfiniteViewer();
        if (!infiniteViewer) return { x: 0, y: 0 };

        let scrollLeft = 0;
        let scrollTop = 0;

        try {
            scrollLeft = InfiniteViewerHelpers.getInfiniteViewer().getScrollLeft();
            scrollTop = InfiniteViewerHelpers.getInfiniteViewer().getScrollTop();
        } catch {
            /* empty */
        }

        return { x: scrollLeft, y: scrollTop };
    }

    /**
     * Scroll to the given coordinates.
     * @param x - X coordinate.
     * @param y - Y coordinate.
     */
    static scrollTo(x: ScrollPosition['x'], y: ScrollPosition['y']): void {
        try {
            const infiniteViewer = InfiniteViewerHelpers.getInfiniteViewer();
            if (!infiniteViewer) return;
            infiniteViewer.scrollTo(x, y);
        } catch {
            /* empty */
        }
    }

    /**
     * Get the zoom level.
     * @returns Zoom level.
     */
    static getZoomLevel(): number {
        const infiniteViewer = InfiniteViewerHelpers.getInfiniteViewer();
        if (!infiniteViewer) return DEFAULT_ZOOM_LEVEL;
        return infiniteViewer.props.zoom;
    }

    /**
     * Zoom to the given level.
     * @param zoom - Zoom level.
     */
    static setZoomLevel(zoomLevel: number, saveZoomLevel = true): void {
        try {
            const infiniteViewer = InfiniteViewerHelpers.getInfiniteViewer();
            if (!infiniteViewer) return;
            if (zoomLevel < MIN_ZOOM) zoomLevel = MIN_ZOOM;
            if (zoomLevel > MAX_ZOOM) zoomLevel = MAX_ZOOM;
            EventEmitterHelpers.sent(EventEmitterTypes.TDzoomLevel, zoomLevel);
            infiniteViewer.setZoom(zoomLevel);

            if (saveZoomLevel) {
                const templateId = getTemplateData('id');
                ViewState.set('templateDesigner', CANVAS_ZOOM_LEVEL + '_' + templateId, zoomLevel, 'session');
            }
        } catch {
            /* empty */
        }
    }

    /**
     * Center the scrollable area to the center of the canvas.
     */
    static scrollToCenter = (): void => {
        const infiniteViewer = InfiniteViewerHelpers.getInfiniteViewer();
        if (!infiniteViewer) return;

        const viewer = infiniteViewer.infiniteViewer;
        const scrollArea = viewer.scrollAreaElement;

        // Get total scrollable width/height.
        const scrollWidth = scrollArea.scrollWidth;
        const scrollHeight = scrollArea.scrollHeight;

        // Get the container width/height.
        const containerWidth = scrollArea.offsetWidth;
        const containerHeight = scrollArea.offsetHeight;

        // Ensure we calculate the center correctly.
        const x = scrollWidth / 2 - containerWidth / 2;
        const y = scrollHeight / 2 - containerHeight / 2;

        // Scroll Infinite Viewer to calculated center.
        viewer.scrollTo(x, y);
    };

    /**
     * Scroll to the center of the canvas so that all formats are visible.
     */
    static zoomFit = (formats?: Format[]): void => {
        const infiniteViewerContainer = InfiniteViewerHelpers.getContainerSize();
        if (!infiniteViewerContainer) return;

        const infiniteViewerContainerWidth = infiniteViewerContainer.width;
        const infiniteViewerContainerHeight = infiniteViewerContainer.height;

        if (formats === undefined) formats = getTemplateData<Template['formats']>('formats');

        let maxWidth = 0;
        let maxHeight = 0;

        // Find maximum values for width, height, x, and y.
        formats.forEach((format) => {
            maxWidth = Math.max(maxWidth, format.width + (format.x ?? 0));
            maxHeight = Math.max(maxHeight, format.height + (format.y ?? 0));
        });

        // Calculate container width and height.
        const containerWidth = maxWidth + InfiniteViewerHelpers.MARGIN;
        const containerHeight = maxHeight + InfiniteViewerHelpers.MARGIN;

        let zoom = 1;

        if (containerWidth > infiniteViewerContainerWidth / zoom) {
            zoom = infiniteViewerContainerWidth / containerWidth;
        }

        if (containerHeight > infiniteViewerContainerHeight / zoom) {
            zoom = infiniteViewerContainerHeight / containerHeight;
        }

        // Round the custom zoom.
        zoom = Math.round(zoom * 100) / 100;

        InfiniteViewerHelpers.setZoomLevel(zoom);
        InfiniteViewerHelpers.setZoomLevelFit(zoom);
        InfiniteViewerHelpers.scrollTo(-24 / zoom, -24 / zoom);
    };
}

export { InfiniteViewerHelpers };
