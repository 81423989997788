import get from 'lodash/get';
import { InterfaceSetupExport } from 'types/interfaceSetupExport.type';
import InterfaceSetupInput from 'types/InterfaceSetupInput.type';
import merge from 'lodash/merge';
import set from 'lodash/set';
import Translation from 'components/data/Translation';
import defaultRatios from 'components/assets/AssetGalleryCropper/data/default-ratios';
import Setup from 'components/data/Setup';
import Template, { DesignerSettings, TemplateData, View } from '../types/template.type';
import { getTemplateData } from './data.helpers';
import { DynamicLayerHelpers } from './dynamic-layer.helpers';
import FrameType from '../types/frameTypes.type';
import {
    AllConditions,
    ConditionOperator,
    ConditionType,
    DynamicLayerInput,
    FeedItemSelectorInput,
    FieldCondition,
    AllSettings
} from '../types/dynamicLayer.type';
import { TemplateVersionHelpers } from './template-version.helpers';
import Layer from '../types/layer.type';
import cloneDeep from '../utils/cloneDeep';
import LayerHelpers from './layer.helpers';
import { LayerPropertiesHelpers } from './layer-properties.helpers';
import { Attributes } from '../types/attribute.type';
import { StylingHelpers } from './styling.helpers';
import { ANIMATION_LAYER_TYPES } from '../constants';
import { attributeInputs } from '../components/dynamic-layers/components/dynamic-layer-edit/config/attribute-inputs';
import TemplateDesignerStore from '../data/template-designer-store';
import { ConfigHelpers } from './config.helpers';
import { FeedMappingHelpers } from './feed-mapping.helpers';

class InterfaceSetupHelpers {
    /**
     * Build the interface setup for the Creative Builder.
     * @param dynamicLayers - Dynamic layers.
     * @param preview - If preview is enabled for the dynamic layers preview.
     * @param useConditions - If conditions should be used.
     * @returns Interface setup.
     */
    static buildInterfaceSetup = (
        dynamicLayers?: Template['dynamicLayers'],
        options?: {
            enableAnimations?: DesignerSettings['enableAnimations'];
            enableLottie?: DesignerSettings['enableLottie'];
            dynamicLayerPreview?: boolean;
            useConditions?: boolean;
        }
    ): { interfaceSetup: InterfaceSetupExport[]; templateDefaultData: Template['templateDefaultData'] } => {
        if (dynamicLayers === undefined) dynamicLayers = getTemplateData<Template['dynamicLayers']>('dynamicLayers');

        const {
            enableAnimations = getTemplateData<DesignerSettings['enableAnimations']>('designerSettings.enableAnimations'),
            enableLottie = getTemplateData<DesignerSettings['enableLottie']>('designerSettings.enableLottie'),
            dynamicLayerPreview = false,
            useConditions = true
        } = options || {};

        const templateType = getTemplateData<Template['templateData']['type']>('templateData.type');
        const layerProperties = getTemplateData<Template['layerProperties']>('layerProperties');
        const frameTypes = getTemplateData<Template['frameTypes']>('frameTypes');
        const feedMapping = getTemplateData<Template['dataVariables']>('dataVariables');
        const disableBase = getTemplateData<DesignerSettings['disableBase']>('designerSettings.disableBase');
        const templateDefaultData = {};
        let interfaceSetup;

        switch (templateType) {
            case 'displayAdDesigned': {
                interfaceSetup = this.createDisplayAdDesignInterfaceSetup(
                    layerProperties,
                    frameTypes,
                    dynamicLayers,
                    feedMapping,
                    enableAnimations,
                    enableLottie,
                    disableBase,
                    dynamicLayerPreview,
                    useConditions,
                    templateDefaultData
                );
                break;
            }
            case 'dynamicImageDesigned':
            case 'dynamicPDFDesigned': {
                interfaceSetup = this.createDisplayImageDesignInterfaceSetup(
                    dynamicLayers,
                    feedMapping,
                    frameTypes,
                    layerProperties,
                    enableAnimations,
                    enableLottie,
                    dynamicLayerPreview,
                    useConditions,
                    templateDefaultData
                );
                break;
            }
            case 'dynamicVideoDesigned':
            case 'dynamicAfterEffects':
            case 'dynamicInDesign': {
                interfaceSetup = this.createDisplayVideoDesignInterfaceSetup(
                    dynamicLayers,
                    feedMapping,
                    frameTypes,
                    layerProperties,
                    enableAnimations,
                    enableLottie,
                    dynamicLayerPreview,
                    useConditions,
                    templateDefaultData
                );
                break;
            }
            default: {
                interfaceSetup = this.createDefaultDesignInterfaceSetup(
                    dynamicLayers,
                    feedMapping,
                    frameTypes,
                    layerProperties,
                    enableAnimations,
                    enableLottie,
                    dynamicLayerPreview,
                    useConditions,
                    templateDefaultData
                );
                break;
            }
        }

        TemplateDesignerStore.save(['templateDefaultData', templateDefaultData], { saveToHistory: false });
        return { interfaceSetup, templateDefaultData };
    };

    /**
     * Create the interface setup for the display ad design.
     * @param layerProperties - The whole layer properties.
     * @param frameTypes - All frametypes.
     * @param dynamicLayers  - Dynamic layers.
     * @param feedMapping - Feed mapping.
     * @param enableAnimations - If animations are enabled.
     * @param enableLottie - If lottie is enabled.
     * @param disableBase - If base is disabled.
     * @param useConditions - If conditions should be used.
     * @returns Interface setup.
     */
    private static createDisplayAdDesignInterfaceSetup = (
        layerProperties: Template['layerProperties'],
        frameTypes: Template['frameTypes'],
        dynamicLayers: Template['dynamicLayers'],
        feedMapping: Template['dataVariables'],
        enableAnimations: DesignerSettings['enableAnimations'],
        enableLottie: DesignerSettings['enableLottie'],
        disableBase: DesignerSettings['disableBase'],
        dynamicLayerPreview: boolean,
        useConditions: boolean,
        templateDefaultData: Template['templateDefaultData']
    ): InterfaceSetupExport[] => {
        const frameType = getTemplateData<View['frameType']>('view.frameType');
        const dynamicFrames = getTemplateData<Template['templateData']['settings']['dynamicFrames']>('templateData.settings.dynamicFrames');

        const frameTypeKeys = (() => {
            const keys = Object.keys(dynamicLayers).filter((key) => key !== 'base');
            return dynamicLayerPreview ? keys.filter((key) => key === frameType) : keys;
        })();

        const subSections = this.transfomToSubSection(
            frameTypeKeys,
            dynamicLayers,
            feedMapping,
            layerProperties,
            frameTypes,
            templateDefaultData,
            enableAnimations,
            enableLottie,
            useConditions
        );

        const availableFrametypes = frameTypes.filter((frameType) => frameType.key !== 'base');

        const interfaceSetup: InterfaceSetupExport[] = [];

        if (!disableBase) {
            const baseFeedSelectorInput = DynamicLayerHelpers.findFeedSelectorInput('base');

            // Create base inputs.
            const baseItems = this.convertInputsToInterfaceSetupInputs(
                dynamicLayers['base'],
                feedMapping['base'],
                baseFeedSelectorInput,
                false,
                'base',
                layerProperties,
                frameTypes,
                templateDefaultData,
                enableAnimations,
                enableLottie
            );

            const hadMainCropImage = this.hasBackgroundImage(baseItems);
            const section: InterfaceSetupExport = {
                title: Translation.get('general.labels.base', 'template-designer'),
                path: 'base',
                items: baseItems
            };
            if (hadMainCropImage) {
                section.backgroundImage = {};
            }
            interfaceSetup.push(section);
        }

        if (availableFrametypes.length > 1 || (subSections && subSections.length) > 0 || dynamicFrames) {
            const hadMainCropImage = this.hasBackgroundImage(subSections);
            const section: InterfaceSetupExport = {
                title: Translation.get('general.labels.design', 'template-designer'),
                path: 'dynamicFrame',
                items: [this.addFrameTypeSelectionInput(availableFrametypes, dynamicLayerPreview), ...subSections]
            };
            if (hadMainCropImage) {
                section.backgroundImage = {};
            }
            interfaceSetup.push(section);
        }

        return interfaceSetup;
    };

    /**
     * Create the interface setup for the dynamic image design.
     * @param dynamicLayers  - Dynamic layers.
     * @param feedMapping - Feed mapping.
     * @param frameTypes - All frametypes.
     * @param layerProperties - The whole layer properties.
     * @param enableAnimations - If animations are enabled.
     * @param enableLottie - If lottie is enabled.
     * @param useConditions - If conditions should be used.
     * @returns Interface setup.
     */
    private static createDisplayImageDesignInterfaceSetup = (
        dynamicLayers: Template['dynamicLayers'],
        feedMapping: Template['dataVariables'],
        frameTypes: Template['frameTypes'],
        layerProperties: Template['layerProperties'],
        enableAnimations: DesignerSettings['enableAnimations'],
        enableLottie: DesignerSettings['enableLottie'],
        dynamicLayerPreview: boolean,
        useConditions: boolean,
        templateDefaultData: Template['templateDefaultData']
    ): InterfaceSetupExport[] => {
        const frameType = getTemplateData<View['frameType']>('view.frameType');

        const frameTypeKeys = (() => {
            const keys = Object.keys(dynamicLayers).filter((key) => key !== 'base');
            return dynamicLayerPreview ? keys.filter((key) => key === frameType) : keys;
        })();

        const subSections = this.transfomToSubSection(
            frameTypeKeys,
            dynamicLayers,
            feedMapping,
            layerProperties,
            frameTypes,
            templateDefaultData,
            enableAnimations,
            enableLottie,
            useConditions
        );

        const availableFrametypes = frameTypes.filter((frameType) => frameType.key !== 'base');

        const interfaceSetup: InterfaceSetupExport[] = [];
        if (availableFrametypes.length > 1 || (subSections && subSections.length) > 0) {
            interfaceSetup.push({
                title: Translation.get('general.labels.design', 'template-designer'),
                path: 'dynamicFrame',
                items: [this.addFrameTypeSelectionInput(availableFrametypes, dynamicLayerPreview), ...subSections]
            });
        }

        return interfaceSetup;
    };

    /**
     * Create the interface setup for the dynamic video design.
     * @param dynamicLayers  - Dynamic layers.
     * @param feedMapping - Feed mapping.
     * @param frameTypes - All frametypes.
     * @param layerProperties - The whole layer properties.
     * @param enableAnimations - If animations are enabled.
     * @param enableLottie - If lottie is enabled.
     * @param useConditions - If conditions should be used.
     * @returns Interface setup.
     */
    private static createDisplayVideoDesignInterfaceSetup = (
        dynamicLayers: Template['dynamicLayers'],
        feedMapping: Template['dataVariables'],
        frameTypes: Template['frameTypes'],
        layerProperties: Template['layerProperties'],
        enableAnimations: DesignerSettings['enableAnimations'],
        enableLottie: DesignerSettings['enableLottie'],
        dynamicLayerPreview: boolean,
        useConditions: boolean,
        templateDefaultData: Template['templateDefaultData']
    ): InterfaceSetupExport[] => {
        const templateType = getTemplateData<TemplateData['type']>('templateData.type');
        const frameType = getTemplateData<View['frameType']>('view.frameType');

        const frameTypeKeys = (() => {
            const keys = Object.keys(dynamicLayers).filter((key) => key !== 'base');
            return dynamicLayerPreview ? keys.filter((key) => key === frameType) : keys;
        })();

        const subSections = this.transfomToSubSection(
            frameTypeKeys,
            dynamicLayers,
            feedMapping,
            layerProperties,
            frameTypes,
            templateDefaultData,
            enableAnimations,
            enableLottie,
            useConditions
        );

        const availableFrametypes = frameTypes.filter((f) => f.key !== 'base');

        const interfaceSetup: InterfaceSetupExport[] = [];
        if (availableFrametypes.length > 1 || (subSections && subSections.length) > 0) {
            const dynamicFrame: InterfaceSetupExport = {
                title: Translation.get('general.labels.design', 'template-designer'),
                path: 'dynamicFrame',
                items: []
            };

            if (!['dynamicAfterEffects', 'dynamicInDesign'].includes(templateType)) {
                dynamicFrame.items.push(this.addFrameTypeSelectionInput(availableFrametypes, dynamicLayerPreview));
            }

            dynamicFrame.items.push(...subSections);
            interfaceSetup.push(dynamicFrame);
        }

        return interfaceSetup;
    };

    /**
     * Create the interface setup for the default design.
     * @param dynamicLayers  - Dynamic layers.
     * @param feedMapping - Feed mapping.
     * @param frameTypes - All frametypes.
     * @param layerProperties - The whole layer properties.
     * @param enableAnimations - If animations are enabled.
     * @param enableLottie - If lottie is enabled.
     * @param useConditions - If conditions should be used.
     * @returns Interface setup.
     */
    private static createDefaultDesignInterfaceSetup = (
        dynamicLayers: Template['dynamicLayers'],
        feedMapping: Template['dataVariables'],
        frameTypes: Template['frameTypes'],
        layerProperties: Template['layerProperties'],
        enableAnimations: DesignerSettings['enableAnimations'],
        enableLottie: DesignerSettings['enableLottie'],
        dynamicLayerPreview: boolean,
        useConditions: boolean,
        templateDefaultData: Template['templateDefaultData']
    ): InterfaceSetupExport[] => {
        const frameType = getTemplateData<View['frameType']>('view.frameType');

        const frameTypeKeys = (() => {
            const keys = Object.keys(dynamicLayers).filter((key) => key !== 'base');
            return dynamicLayerPreview ? keys.filter((key) => key === frameType) : keys;
        })();

        const subSections = this.transfomToSubSection(
            frameTypeKeys,
            dynamicLayers,
            feedMapping,
            layerProperties,
            frameTypes,
            templateDefaultData,
            enableAnimations,
            enableLottie,
            useConditions
        );

        return [
            {
                title: Translation.get('general.labels.asset', 'template-designer'),
                path: 'general',
                items: subSections
            }
        ];
    };

    /**
     * Add frame type selection input.
     * @param availableFrametypes - Available frame types.
     * @returns Interface setup input.
     */
    private static addFrameTypeSelectionInput = (availableFrametypes: FrameType[], hide: boolean): InterfaceSetupInput => {
        return {
            type: 'select',
            model: 'type',
            size: 'large',
            label: Translation.get('general.labels.frameType', 'template-designer'),
            setFirstOption: availableFrametypes[0] && availableFrametypes[0].key ? true : false,
            hidden: hide || availableFrametypes.length === 1,
            options: {
                ...availableFrametypes.reduce((allFrameTypes, currentFrameType) => {
                    allFrameTypes[currentFrameType.key] = currentFrameType.title;
                    return allFrameTypes;
                }, {})
            }
        };
    };

    /**
     * Transforms the dynamic layers to sub sections.
     * @param frameTypeKeys - Frame type keys.
     * @param dynamicLayers - Dynamic layers.
     * @param feedMapping - Feed mapping.
     * @param layerProperties - The whole layer properties.
     * @param frameTypes - All frametypes.
     * @param enableAnimations - If animations are enabled.
     * @param enableLottie - If lottie is enabled.
     * @param useConditions - If conditions should be used.
     * @returns Sub sections.
     */
    private static transfomToSubSection = (
        frameTypeKeys: string[],
        dynamicLayers: Template['dynamicLayers'],
        feedMapping: Template['dataVariables'],
        layerProperties: Template['layerProperties'],
        frameTypes: Template['frameTypes'],
        templateDefaultData: Template['templateDefaultData'],
        enableAnimations: DesignerSettings['enableAnimations'],
        enableLottie: DesignerSettings['enableLottie'],
        useConditions?: boolean
    ): InterfaceSetupInput[] => {
        const templateType = getTemplateData<TemplateData['type']>('templateData.type');

        return frameTypeKeys.reduce((allInputs, frameType) => {
            const feedSelector = DynamicLayerHelpers.findFeedSelectorInput(frameType);

            allInputs.push(
                ...this.convertInputsToInterfaceSetupInputs(
                    dynamicLayers[frameType],
                    feedMapping[frameType],
                    feedSelector,
                    templateType && ['dynamicAfterEffects', 'dynamicInDesign'].includes(templateType) ? '' : `blockData.type==='${frameType}'`,
                    frameType,
                    layerProperties,
                    frameTypes,
                    templateDefaultData,
                    enableAnimations,
                    enableLottie,
                    undefined,
                    useConditions
                )
            );

            return allInputs;
        }, [] as InterfaceSetupInput[]);
    };

    /**
     * Convert the dynamic layer inputs to interface setup inputs.
     * @param dynamicLayerInputs - Dynamic layer inputs.
     * @param feedMapping - Feed mapping.
     * @param feedSelector - If there is an feed selector.
     * @param parentCondition - Condition for the input.
     * @param frameKey - Current frame key.
     * @param layerProperties - The whole layer properties.
     * @param frameTypes - All frametypes.
     * @param enableAnimations - If animations are enabled.
     * @param enableLottie - If lottie is enabled.
     * @param parentInput - Parent input.
     * @param useConditions - If conditions should be used.
     * @param preview - If preview is enabled.
     * @returns Interface setup inputs.
     */
    private static convertInputsToInterfaceSetupInputs = (
        dynamicLayerInputs: DynamicLayerInput[],
        feedMapping: Template['dataVariables'][string],
        feedSelector: FeedItemSelectorInput | null,
        parentCondition: string | false,
        frameKey: FrameType['key'],
        layerProperties: Template['layerProperties'],
        frameTypes: Template['frameTypes'],
        templateDefaultData: Template['templateDefaultData'],
        enableAnimations: DesignerSettings['enableAnimations'],
        enableLottie: DesignerSettings['enableLottie'],
        parentInput: any | undefined = undefined, // TODO: Add type. In this function we add additional properties to the input object which is not in the type.
        useConditions?: boolean
    ): InterfaceSetupInput[] => {
        const templateType = getTemplateData<TemplateData['type']>('templateData.type');
        // Loop through every item in the TD interface setup model
        return dynamicLayerInputs.reduce((allInputs, currentInput) => {
            const { condition, childCondition }: { condition: string | false; childCondition: string | false } = useConditions
                ? this.generateCondition(currentInput, frameKey, parentCondition)
                : { condition: false, childCondition: false };

            // Update the settings of the input if needed with the latest template data. For example change the color of the input to the actual color of the layer.
            if ('layerKey' in currentInput) {
                const layer = LayerHelpers.findLayer(currentInput.layerKey);
                if (layer || currentInput.layerKey === 'formatData') {
                    const key = currentInput.layerKey === 'formatData' ? 'format' : (layer && layer.type) || '';
                    const updateSettings = attributeInputs[key]?.all[currentInput.attribute]?.updateSettings;
                    if (updateSettings && typeof updateSettings === 'function') {
                        const newSettings = updateSettings(
                            layer || { key: currentInput.layerKey },
                            currentInput.attribute,
                            currentInput.settings,
                            frameKey
                        ) as AllSettings & {
                            [key: string]: unknown;
                        };
                        currentInput.settings = newSettings;
                    }
                }
            }

            switch (currentInput.type) {
                case 'group': {
                    const group: InterfaceSetupInput = {
                        type: 'subSection',
                        opened: (() => {
                            if (!useConditions) return true;
                            if (currentInput.settings && currentInput.settings.opened !== undefined) return currentInput.settings.opened;
                            return true;
                        })(),
                        title: currentInput.label,
                        condition: '',
                        tooltip: currentInput.tooltip,
                        helperText: currentInput.helperText,
                        inputKey: currentInput.key,
                        items:
                            currentInput.children && currentInput.children.length
                                ? this.convertInputsToInterfaceSetupInputs(
                                      currentInput.children,
                                      feedMapping,
                                      feedSelector,
                                      childCondition,
                                      frameKey,
                                      layerProperties,
                                      frameTypes,
                                      templateDefaultData,
                                      enableAnimations,
                                      enableLottie,
                                      undefined,
                                      useConditions
                                  )
                                : []
                    };
                    if (condition) group.condition = condition;
                    allInputs.push(group);
                    break;
                }
                case 'divider': {
                    const divider: InterfaceSetupInput = {
                        type: 'divider',
                        inputKey: currentInput.key,
                        condition: ''
                    };
                    if (condition) divider.condition = condition;
                    allInputs.push(divider);
                    break;
                }
                case 'alert': {
                    const alert: InterfaceSetupInput = {
                        type: 'alert',
                        message: '',
                        inputKey: currentInput.key,
                        alertType: 'error'
                    };
                    if (currentInput.label) alert.message = currentInput.settings.message as string;
                    if (currentInput.settings.alertType) alert.alertType = currentInput.settings.alertType as string;
                    if (condition) alert.condition = condition;
                    allInputs.push(alert);
                    break;
                }
                case 'customInput': {
                    const customInput: InterfaceSetupInput = {
                        ...currentInput.settings,
                        inputKey: currentInput.key
                    };
                    allInputs.push(customInput);
                    break;
                }
                case 'dropdown': {
                    const dropdown: InterfaceSetupInput = {
                        type: currentInput.settings.type,
                        model: this.generateModel(currentInput),
                        inputKey: currentInput.key,
                        label: currentInput.label
                    };

                    if (currentInput.settings.options) {
                        dropdown.options = currentInput.settings.options.filter((option) => {
                            if (option.key === '' || option.value === '') return false;
                            return true;
                        });
                    }

                    if (condition) dropdown.condition = condition;
                    if (dropdown.options && dropdown.options.length > 0) dropdown.defaultValue = dropdown.options[0].key;

                    if (currentInput.settings.languageSpecific) {
                        dropdown.model = dropdown.model += '.[[language]]';
                    }

                    allInputs.push(dropdown);
                    break;
                }
                case 'feedSelectorInput': {
                    if (!Object.keys(feedMapping).length) {
                        return allInputs;
                    }

                    const feedSelectorInput: InterfaceSetupInput = {
                        type: currentInput.type,
                        label: currentInput.label,
                        model: this.generateModel(currentInput),
                        inputKey: currentInput.key,
                        display: currentInput.settings.display,
                        useObject: TemplateVersionHelpers.shouldUseObjectForFeedSelector(),
                        compact: currentInput.settings.compact ?? false,
                        feedTitle: currentInput.settings.feedTitle ?? '',
                        mapping: {},
                        condition: ''
                    };

                    if (condition) feedSelectorInput.condition = condition;
                    const getObjectModel = (attribute: string): string => {
                        if (attribute === 'text') return '.value';
                        return '';
                    };

                    Object.keys(feedMapping).forEach((layerKey) => {
                        Object.keys(feedMapping[layerKey]).forEach((attribute) => {
                            if (!feedSelectorInput.mapping) return;
                            feedSelectorInput.mapping[layerKey + '.' + attribute + getObjectModel(attribute)] = feedMapping[layerKey][attribute];
                        });
                    });

                    if (feedSelectorInput.mapping && Object.keys(feedSelectorInput.mapping).length === 0) {
                        return allInputs;
                    }

                    allInputs.push(feedSelectorInput);
                    break;
                }
                default: {
                    const layer = LayerHelpers.findLayer(currentInput.layerKey, frameKey);
                    if (!enableAnimations && layer?.type && ANIMATION_LAYER_TYPES.includes(layer.type)) return allInputs;
                    if (!enableLottie && layer?.type === 'lottie') return allInputs;

                    const settings = cloneDeep(currentInput.settings);
                    settings.model = this.generateModel(currentInput);
                    settings.inputKey = currentInput.key;
                    settings.label = currentInput.label;
                    settings.helperText = currentInput.helperText;
                    settings.tooltip = currentInput.tooltip;

                    if (settings.condition || condition) {
                        // If the condition depends on the parent and the parent is a text field, make sure that it also checks if the text is set
                        // (otherwise it won't disappear if you enter a text and delete the text afterwards)
                        if (
                            parentInput?.settings &&
                            [
                                'text',
                                'textMultiLanguage',
                                'textSelect',
                                'textSelectMultiLanguage',
                                'selectMultiLanguage',
                                'radioListMultiLanguage',
                                'radioList'
                            ].includes(parentInput.settings.type)
                        ) {
                            settings.showIfTextIsSet = parentInput.model;
                            settings.condition = condition;
                        } else {
                            settings.condition = settings.condition && condition ? `${condition}&&${settings.condition}` : settings.condition || condition;
                        }
                    }

                    /**
                     * If the type is a select or radioList, then filter out the empty options.
                     */
                    if ((settings.type === 'select' || settings.type === 'radioList') && settings.options) {
                        settings.options = settings.options.filter((option) => {
                            if (option.key === '' || option.value === '') return false;
                            return true;
                        });
                    }

                    /**
                     * If the type is a slider, then check if the min, max and step are numbers.
                     * If they are not numbers, then set them to default values.
                     * If the max is smaller than the min, then set marks to false.
                     */
                    if (settings.type === 'slider') {
                        if (isNaN(settings.min)) settings.min = 0;
                        if (isNaN(settings.max)) settings.max = 100;
                        if (isNaN(settings.step)) settings.step = 1;

                        if (settings.max < settings.min) {
                            settings.marks = false;
                        }
                    }

                    /**
                     * If the type is an Adobe type, then set inverted to false.
                     * In other template types, inverted is true for the Creative Builder.
                     * But for Adobe types, we need to set it to false in order to have
                     * the toggle work properly in the Creative Builder.
                     */
                    if (settings.type === 'switch' && templateType && ['dynamicAfterEffects', 'dynamicInDesign'].includes(templateType)) {
                        settings.inverted = false;
                    }

                    if (settings.type === 'assetGalleryInput') {
                        /**
                         * If canUseContentSpace is undefined, then set it to false.
                         * Otherwise it will be shown in the Creative Builder.
                         */
                        if (settings.canUseContentSpace === undefined) {
                            settings.canUseContentSpace = false;
                        }

                        /**
                         * If the mode is ratioBased and it doesn't have ratios, then it is an old input and ratios needs to be inserted.
                         */
                        if ((settings.mode === 'ratioBased' || settings.cropMode === 'ratioBased') && settings.ratios === undefined) {
                            settings.ratios = defaultRatios;
                        }

                        /**
                         * Set Aprimo to false if it is turned off in the designer settings.
                         */
                        if (!DynamicLayerHelpers.hasAprimo()) {
                            settings.canUseAprimo = false;
                        }

                        /**
                         * Set Unsplash to false if it is turned off in the designer settings.
                         */
                        if (!DynamicLayerHelpers.hasUnsplash()) {
                            settings.canUseUnsplash = false;
                        }

                        /**
                         * Set the file type for the upload.
                         */
                        settings.fileType = ConfigHelpers.getFileTypesForUpload(layer ?? undefined);
                    }

                    const inputLinked = (() => {
                        if (!('layerKey' in currentInput)) return false;
                        return FeedMappingHelpers.isLinkedToInput(feedMapping, currentInput.layerKey, currentInput.attribute);
                    })();

                    if (settings.type === 'text' && templateType && !['dynamicAfterEffects', 'dynamicInDesign'].includes(templateType)) {
                        settings.model = settings.model += '.value';
                    }

                    if ((inputLinked && TemplateVersionHelpers.shouldDisableMultilanguageForAllFeedMapped()) || (inputLinked && feedSelector)) {
                        // If the text type is multi language, then convert it to normal text. Feed selector doesn't support multi languages.
                        if (settings.type === 'textMultiLanguage') {
                            settings.type = 'text';
                            if (!settings.model.endsWith('.value')) {
                                settings.model = settings.model += '.value';
                            }
                            settings.useValueObject = false;
                        }
                        // If the text type is multi language, then convert it to normal text. Feed selector doesn't support multi languages.
                        if (settings.type === 'textSelectMultiLanguage') {
                            settings.type = 'textSelect';
                        }
                        if (settings.type === 'selectMultiLanguage') {
                            settings.type = 'select';
                        }
                        if (settings.type === 'radioListMultiLanguage') {
                            settings.type = 'radioList';
                        }

                        // When read only is undefined, that means it is an old input before feed selector implementation and needs to be true if it is not overwritten in the feed selector input.
                        if (settings.readOnly === undefined) {
                            settings.readOnly = true;
                        }

                        if (settings.type === 'text') {
                            if (!settings.model.endsWith('.value')) {
                                settings.model = settings.model += '.value';
                            }
                            settings.useValueObject = false;
                        }

                        if (settings.type === 'switch' && feedSelector) {
                            settings.inverted = false;
                        }

                        if (feedSelector) {
                            //Add feedItem to the beginning of the model if it is a feed item
                            //This way the data of the feed and the data of the input end up in the same spot.
                            //feedItem is hardcoded for now because there can only be one feed item
                            settings.model = 'feedItem.' + settings.model;
                        }
                    }

                    // For some inputs we don't want to get the default data from the layer properties. Because these get set in the layer input settings.
                    if (!['predefinedImages'].includes(currentInput.attribute)) {
                        // Saving the default data for the input in the template designer store.
                        const templateValue = this.getTemplateValue(
                            layerProperties,
                            frameTypes,
                            currentInput,
                            layer,
                            frameKey,
                            inputLinked && !!feedSelector,
                            false
                        );
                        // We are saving the inputs that are language specific by default under the EN language. This way its easier to use the data in the creative builder.
                        // In the creative builder we write this data to the defaultLanguage anyway. It that ever changes the template will still work.
                        const editorModel = this.generateEditorModel({ ...currentInput, settings: settings })?.replace('[[[language]]]', `.EN`);
                        Object.keys(templateValue).map((overwriteKey) => {
                            set(templateDefaultData, `${overwriteKey}.${editorModel}`, templateValue[overwriteKey]);
                        });
                    }

                    if (settings.languageSpecific) {
                        settings.model = settings.model += '.[[language]]';
                    }

                    if (layer?.type === 'audio' && settings.type === 'assetGalleryInput' && settings.useDurationOfLayer && layer.key !== 'base') {
                        //If layer is audio and gallery input and the user selected use duration of layer
                        const visibilityArray = layerProperties.general[frameKey][layer.key]?.visibility || [0, 1];
                        const duration =
                            frameTypes.find((frameType) => layerProperties.general[frameType.key][layer?.key || ''] && frameType.key !== 'base')?.duration || 0;
                        settings.maximumDuration = (visibilityArray[1] - visibilityArray[0]) * duration;
                        settings.minimumDuration = (visibilityArray[1] - visibilityArray[0]) * duration;
                    }

                    delete settings.languageSpecific;

                    allInputs = allInputs.concat([
                        currentInput.attribute === 'customInput' ? currentInput.settings : settings,
                        ...this.convertInputsToInterfaceSetupInputs(
                            currentInput.children || [],
                            feedMapping,
                            feedSelector,
                            childCondition,
                            frameKey,
                            layerProperties,
                            frameTypes,
                            templateDefaultData,
                            enableAnimations,
                            enableLottie,
                            currentInput.attribute === 'customInput' ? currentInput.settings : settings,
                            useConditions
                        )
                    ]);
                }
            }

            return allInputs;
        }, [] as InterfaceSetupInput[]);
    };

    /**
     * Get the template value for the interface setup input based on the layer property and css property.
     * @param layerProperties - Layer properties.
     * @param frameTypes - Frame types.
     * @param dynamicLayerInput - Dynamic layer input.
     * @param layer - Layer of the input.
     * @param frameKey - frame type key.
     * @returns Template value for the interface setup input.
     */
    static getTemplateValue = (
        layerProperties: Template['layerProperties'],
        frameTypes: FrameType[],
        dynamicLayerInput: DynamicLayerInput,
        layer: Layer | null,
        frameKey: FrameType['key'],
        inputLinked = false,
        useLanguageObject = true
    ): { [key: string]: number | string } => {
        const templateValue = {};
        Object.keys(layerProperties).forEach((key) => {
            if (dynamicLayerInput.attribute === 'frameDuration') {
                const frame = frameTypes.find((item) => item.key === frameKey);
                if (!frame || !frame.duration) return;
                // change duration in seconds to milliseconds because the creative builder is in ms but the template designer is in seconds
                templateValue['general'] = frame.duration * 1000;
                return;
            }

            /**
             * If the attribute is visibility and the template type is dynamicAfterEffects or dynamicInDesign, then set the general visibility to true. Because we can't get the visibility from the layer properties. This is because the visibility is not retrieved from the structured data.
             * The reason why we enable the visibility for Adobe templates is because 9/10 times the layer is visible in the render while the visibility switch is turned off.
             */
            if (dynamicLayerInput.attribute === 'visibility' && layer) {
                if (ConfigHelpers.isAdobeTemplate()) {
                    templateValue['general'] = { [layer.key]: false };
                    return;
                }
            }

            const cssKey = this.matchCSSfromLayerType(dynamicLayerInput.attribute);

            if (!cssKey) return;

            const currentLayerProps = (() => {
                if ('layerKey' in dynamicLayerInput && dynamicLayerInput.layerKey === 'formatData') return layerProperties[key][frameKey];
                if (!layer || !layerProperties[key] || !layerProperties[key][frameKey]) return;
                return layerProperties[key][frameKey][layer.key];
            })();

            if (!currentLayerProps || Array.isArray(currentLayerProps)) return;

            const selectedProperties = 'properties' in currentLayerProps && currentLayerProps.properties;

            if (!selectedProperties) return;

            let value = get(selectedProperties, cssKey);

            // only set the default value of a layer input on general to prevent setting the default value on the format specific props
            // which can override the right template value in the creative builder
            if (key === 'general') {
                // Check if the default value should be used.
                if (value === undefined && layer) {
                    const defaultLayerProperties = LayerPropertiesHelpers.getDefaultProperties(layer.type);
                    const defaultValue = get(defaultLayerProperties, cssKey);
                    value = defaultValue;
                }

                if (typeof value === 'object' && layer) {
                    const defaultLayerProperties = LayerPropertiesHelpers.getDefaultProperties(layer.type);
                    const defaultValue = get(defaultLayerProperties, cssKey);
                    value = merge({}, defaultValue, value);
                }
            }

            // We need the template value of display for all values because of the inverted switch situation.
            if (!value && value !== 0 && cssKey !== 'display') return;

            // if defaultHidden is enabled it shouldnt set a default value because its hidden for general and format specific props
            if (key === 'general' && selectedProperties['canEdit'] && selectedProperties['canEdit']['defaultHidden'] === true) {
                return;
            } else {
                const currentLayerProps = (() => {
                    if ('layerKey' in dynamicLayerInput && dynamicLayerInput.layerKey === 'formatData') {
                        return layerProperties['general'][frameKey];
                    }
                    if (!layer || !layerProperties['general'] || !layerProperties['general'][frameKey]) return;

                    return layerProperties['general'][frameKey][layer.key];
                })();

                if (!currentLayerProps || Array.isArray(currentLayerProps)) return;

                const generalProperties = 'properties' in currentLayerProps && currentLayerProps.properties;

                if (generalProperties['canEdit'] && generalProperties['canEdit']['defaultHidden'] === true) return;
            }

            const transformedValue = this.transformValueToTemplateValue(
                dynamicLayerInput.attribute,
                value,
                dynamicLayerInput.settings,
                inputLinked,
                useLanguageObject
            );

            if (transformedValue !== undefined) templateValue[key] = transformedValue;
        });

        return templateValue;
    };

    /**
     * Gets the value for the Creative Builder input.
     * @param attribute - Attribute key.
     * @param value - Value of the layer.
     * @param settings - Input settings.
     * @returns New value for the Creative Builder.
     */
    static transformValueToTemplateValue = (
        type: Attributes,
        value: any,
        settings: DynamicLayerInput['settings'],
        inputLinked: boolean,
        useLanguageObject: boolean
    ) => {
        const templateType = getTemplateData<TemplateData['type']>('templateData.type');

        switch (type) {
            case 'text':
                if (settings && settings.type === 'textMultiLanguage') {
                    if (useLanguageObject) {
                        const defaultLanguage = Setup.get('defaultLanguage');

                        const language = (() => {
                            if (defaultLanguage) {
                                return defaultLanguage;
                            }

                            const languages = Setup.get('languages');
                            if (languages) {
                                return Object.keys(languages)[0];
                            }

                            return 'EN';
                        })();

                        return { [language]: { value } };
                    } else {
                        return value;
                    }
                }

                return value;
            case 'backgroundColor':
            case 'shadowColor':
                return StylingHelpers.convertColor(value);
            case 'borderStyle':
                return value.borderStyle;
            case 'borderColor':
                return StylingHelpers.convertColor(value.borderColor);
            case 'borderWidth':
                return value.borderWidth.top.value;
            case 'borderRadius':
                return value.topLeft.value;
            case 'opacity': {
                return Math.round(value * 100);
            }
            case 'fontSize': {
                const createdDate = getTemplateData<TemplateData['createdDate']>('templateData.createdDate');
                const usesFontScaling = TemplateVersionHelpers.templateUsesFontScaling(createdDate);

                if (usesFontScaling) {
                    // When using font scaling, we don't want to return the value in the TD, because that one is in
                    // pixels and we want to display the scaling option in percentages. We always start at 100(%)
                    return 100;
                } else if (value && value.normal && value.normal.fontSize) {
                    return value.normal.fontSize.value;
                }

                break;
            }
            case 'lineHeight':
                if (value && value.normal && value.normal.lineHeight) return value.normal.lineHeight;
                break;
            case 'textDecoration':
                if (value && value.normal && value.normal.textDecoration) return value.normal.textDecoration;
                break;
            case 'textDecorationStyle':
                if (value && value.normal && value.normal.textDecorationStyle) return value.normal.textDecorationStyle;
                break;
            case 'fontFamily':
                if (value.normal.fontFamily) return value.normal.fontFamily;
                break;
            case 'color':
                if (value?.normal?.color) return StylingHelpers.convertColor(value.normal.color);
                else return;
            case 'textAlign':
                if (value.normal.textAlignHorizontal) return value.normal.textAlignHorizontal;
                break;
            case 'backgroundImage':
                return value.src;
            case 'visibility':
                if (!inputLinked || ConfigHelpers.isAdobeTemplate(templateType)) {
                    return !value;
                } else {
                    return value;
                }
            case 'scale':
                return value * 100;
            case 'rowGap':
            case 'columnGap':
                return value.value;
            case 'verticalAlign':
            case 'horizontalAlign':
                if (typeof value !== 'string') return 'none';
                return value;
            default:
                return value;
        }
    };

    /**
     * Gets the CSS property based on layer type.
     * @param type - Layer property key.
     * @returns CSS property based on layer type.
     */
    static matchCSSfromLayerType = (type: DynamicLayerInput['attribute']): string | undefined => {
        switch (type) {
            case 'text':
                return 'text';
            case 'backgroundColor':
                return 'background';
            case 'borderStyle':
                return 'border';
            case 'borderColor':
                return 'border';
            case 'borderWidth':
                return 'border';
            case 'rotation':
                return 'rotation';
            case 'opacity':
                return 'opacity';
            case 'borderRadius':
                return 'borderRadius';
            case 'fontSize':
            case 'lineHeight':
            case 'color':
            case 'fontFamily':
            case 'textAlign':
            case 'textDecoration':
            case 'textDecorationStyle':
                return 'textStyling';
            case 'backgroundImage':
                return 'backgroundImage';
            case 'visibility':
                return 'display';
            case 'image':
            case 'video':
            case 'predefinedImages':
                return 'media.src';
            case 'horizontalAlign':
                return 'horizontalAlign';
            case 'verticalAlign':
                return 'verticalAlign';
            case 'scale':
                return 'scale';
            case 'flexDirection':
                return 'direction';
            case 'justifyContent':
                return 'justify';
            case 'alignItems':
                return 'align';
            case 'columnGap':
                return 'columnGap';
            case 'rowGap':
                return 'rowGap';
            case 'shadowColor':
                return 'shadow.color';
            case 'objectPosition':
                return 'media.position';
            default:
                return;
        }
    };

    /**
     * Generate the model for the input.
     * @param input - Dynamic layer input.
     */
    static generateModel = (input: DynamicLayerInput): string => {
        const templateType = getTemplateData<TemplateData['type']>('templateData.type');

        // If the input settings already has a model and it is an Adobe template, then don't generate it again. It is added when the input is created.
        // If the input is a custom input it already has a model in the custom json. So we don't need to generate it again.
        if (input.settings['model'] !== undefined && (ConfigHelpers.isAdobeTemplate(templateType) || input.attribute === 'customInput')) {
            return input.settings['model'] as string;
        }

        switch (input.type) {
            case 'feedSelectorInput':
                return 'feedItem';
            case 'alert':
            case 'group':
            case 'divider':
            case 'dropdown':
                return `${input.key}.${input.attribute}`;
            case 'customInput':
                return '';
            default: {
                if (input.overwriteModel) {
                    return `${input.layerKey}.${input.overwriteModel}`;
                }
                return `${input.layerKey}.${input.attribute}`;
            }
        }
    };

    /**
     * Generates the editor model of a dynamic layer input.
     * @param input The dynamic layer input
     * @returns The editor model for the input mainly to check conditions on etc.
     */
    static generateEditorModel = (input: DynamicLayerInput): string | undefined => {
        const inputDataModel = (input && this.generateModel(input)) || '';

        if (!input) return;
        const feedItem =
            'model' in input.settings && typeof input.settings.model === 'string' && input.settings.model.startsWith('feedItem') ? 'feedItem.' : '';
        // In this function we use some variables that are condidionally filled or are an empty string. After that they are concatinated.
        // LanguageSpecific adds the language to the model if the input is a textMultiLanguage input or language specific.
        const languageSpecific =
            input.settings.languageSpecific ||
            ['textMultiLanguage', 'textSelectMultiLanguage', 'selectMultiLanguage', 'radioListMultiLanguage'].includes(input.settings.type)
                ? '[[[language]]]'
                : '';
        // If the input uses a value object the model should contain .value
        const value =
            ('useValueObject' in input.settings && input.settings.useValueObject) ||
            ['textMultiLanguage', 'text', 'textSelectMultiLanguage', 'selectMultiLanguage', 'radioListMultiLanguage'].includes(input.settings.type)
                ? '.value'
                : '';

        const model = `${feedItem}${inputDataModel}${languageSpecific}${value}`;
        return model;
    };

    /**
     * This function converts a condition object in to a condition string.
     * @param conditionData The object with all the data of the condition in it
     * @param frameKey The frame key
     * @returns A string with the condition in it
     */
    static convertCondition = (conditionData: AllConditions, frameKey: FrameType['key']): string => {
        // This types object contains the locations of the relevant data.
        // So the user level is stored in auth.level for example.
        const types = {
            userLevel: 'auth.level',
            userType: 'auth.type',
            userBrand: 'auth.brands',
            userDepartment: 'auth.departments',
            userMarket: 'auth.markets',
            language: 'editor.language',
            market: 'editor.market',
            editorType: 'editor.type'
        };

        if (conditionData.type === 'field') {
            const inputData: DynamicLayerInput | null = DynamicLayerHelpers.findInput(conditionData.fieldKey, frameKey);

            if (!inputData) return '';

            const feedMapping = getTemplateData<Template['dataVariables'][string]>(`dataVariables.${frameKey}`);
            const isLinkedToInput = FeedMappingHelpers.isLinkedToInput(feedMapping, ('layerKey' in inputData && inputData.layerKey) || '', inputData.attribute);
            if (isLinkedToInput && inputData.settings && 'model' in inputData.settings) {
                inputData.settings.model = 'feedItem.' + inputData.settings.model;
            }
            // This is the editor model of the input that is used in the condition
            const editorModel = this.generateEditorModel(inputData);
            // blockData is prepended to the model because the data of the input is stored in the blockData object
            const inputModel = `blockData.${editorModel}`;
            const inputModelParts = inputModel.split('.');

            // If a model is long and its data is nested we need to check if the data exists.
            // This is needed because if the data does not exist the condition will always be false.
            // So all parts of the model need to exists
            const inputModelExists = inputModelParts.reduce((acc, part, index) => {
                const current = inputModelParts.slice(0, index + 1).join('.');
                return acc + (index === 0 ? current : ` && ${current}`);
            }, '');
            // This is true if one part of the model does not exist
            const inputModelDoesNotExist = inputModelParts.reduce((acc, part, index) => {
                const current = inputModelParts.slice(0, index + 1).join('.');
                return acc + (index === 0 ? `!${current}` : ` || !${current}`);
            }, '');

            // Every condition is build up in the same way. Some optional parts like not, image, length etc. Are conditionally filled or are empty strings and then concatenated.
            // These optional parts of the condition are based on the input type and the operator of the condition.
            // This is because the data of some input is a bit different. The assetGalleryInput for example uses a url property where it stores the data.
            if (!conditionData.operator || conditionData.operator === ConditionOperator.IsSet || conditionData.operator === ConditionOperator.IsNotSet) {
                const isNot = conditionData.operator === ConditionOperator.IsSet;
                const not =
                    (isNot && inputData.settings.type === 'switch') || (!isNot && inputData.settings.type !== 'switch')
                        ? `(${inputModelDoesNotExist}) || !`
                        : `(${inputModelExists}) &&`;
                const undefinedCheck = inputData.settings.type !== 'switch' && !(inputData.attribute !== 'text') ? ' !== undefined' : '';
                const length = inputData.attribute === 'text' ? '.length !== 0' : '';

                // e.g. !blockData.layerKey.attribute.url !== undefined
                // e.g. blockData.layerKey.attribute.length !== 0
                // e.g. blockData.layerKey.attribute
                return `(${not}${inputModel}${length}${undefinedCheck})`;
            } else if (conditionData.operator === ConditionOperator.Includes || conditionData.operator === ConditionOperator.DoesNotInclude) {
                const not = conditionData.operator === ConditionOperator.Includes ? `(${inputModelExists}) && ` : `(${inputModelDoesNotExist}) || !`;
                const includes = `.includes('${conditionData.valueToCompare}')`;

                return `(${not}${inputModel}${includes})`;
            } else if (conditionData.operator === ConditionOperator.GreaterThan || conditionData.operator === ConditionOperator.LessThan) {
                return `((${inputModelExists}) && ${inputModel} ${conditionData.operator} ${conditionData.valueToCompare})`;
            } else {
                return `((${inputModelExists}) && ${inputModel} ${conditionData.operator} '${conditionData.valueToCompare}')`;
            }
        } else {
            if (conditionData.operator === ConditionOperator.Includes || conditionData.operator === ConditionOperator.DoesNotInclude) {
                const not = conditionData.operator === ConditionOperator.Includes ? `` : `!`;
                const includes = `.includes('${conditionData.valueToCompare}')`;

                return `(${not}${types[conditionData.type]}${includes})`;
            }
            return `${types[conditionData.type]} ${conditionData.operator} '${conditionData.valueToCompare}'`;
        }
    };

    /**
     * Find the input parents of the given input.
     * @param layerKey  The key of the layer to find the input for.
     * @param attribute The attribute of the input to find.
     * @param dynamicLayers The dynamic layers to search in.
     * @param frameType The frame type to search for the input in.
     * @returns The input parents of the given layer key and attribute.
     */
    static findInputParents = (
        inputKey: DynamicLayerInput['key'],
        dynamicLayers?: Template['dynamicLayers'],
        frameType?: View['frameType']
    ): DynamicLayerInput[] => {
        if (!dynamicLayers) dynamicLayers = getTemplateData<Template['dynamicLayers']>('dynamicLayers');
        if (!frameType) frameType = getTemplateData<View['frameType']>('view.frameType');
        const frameDynamicLayers = dynamicLayers[frameType] || [];

        let foundInputs: DynamicLayerInput[] = [];

        const searchLayer = (layers: DynamicLayerInput[], parents: DynamicLayerInput[]): boolean => {
            for (const layer of layers) {
                const newParents = [...parents, layer];
                if (layer.key === inputKey) {
                    foundInputs = parents;
                    return true; // Found the target layer
                }
                if ('children' in layer && layer.children.length > 0) {
                    if (searchLayer(layer.children, newParents)) {
                        return true; // Found the target layer in children
                    }
                }
            }
            return false; // Target layer not found in this branch
        };

        searchLayer(frameDynamicLayers, []);

        return foundInputs.filter((parent) => !['group', 'alert', 'divider'].includes(parent.type));
    };

    /**
     * Generates the condition for the input and the childCondition
     * @param input The input to generate the condition for
     * @param frameKey Current frame key
     * @returns An array with the full condition for the input and the condition to pass down to the children
     */
    static generateCondition = (
        input: DynamicLayerInput,
        frameType: View['frameType'],
        parentCondition: string | false
    ): { condition: string | false; childCondition: string | false } => {
        // The parent set condition is something the user can not edit. This is generated by the nesting of the inputs.
        // Get the parents and convert them to an array of condition objects
        const inputParents = this.findInputParents(input.key, undefined, frameType);
        const parentSetCondition: FieldCondition[] = inputParents.map((parent) => ({
            type: ConditionType.Field,
            operator: ConditionOperator.IsSet,
            fieldKey: parent.key,
            locked: true
        }));
        // Convert the parent visibility conditions to strings and join them with '&&'
        const parentSetConditionString: string | false = parentSetCondition.length
            ? parentSetCondition.map((condition) => this.convertCondition(condition, frameType)).join(' && ')
            : false;

        let isCustomCondition = false;
        //Convert the or blocks and and blocks to strings and join them with '||' and '&&'
        const conditionString: string | false = (() => {
            if ('condition' in input && input.condition) {
                if (typeof input.condition === 'string') {
                    //This is a custom condition
                    isCustomCondition = true;
                    return input.condition;
                } else {
                    //This is a basic condition
                    const filteredConditions = input.condition.filter((orBlock) => orBlock.length);
                    if (filteredConditions.length) {
                        // Convert the conditions to strings and join them with '&&' and '||' for and and or blocks.
                        const conditions = filteredConditions.map((andBlock) =>
                            andBlock.map((condition) => {
                                const andBlockCondition = this.convertCondition(condition, frameType);
                                return andBlockCondition;
                            })
                        );
                        return conditions.map((andBlock) => andBlock.join(' && ')).join(' || ');
                    }
                }
            }
            return '';
        })();

        let condition = conditionString;
        // If its a custom condition we dont add any other conditions.
        if (!isCustomCondition && parentCondition) {
            condition = condition ? `(${condition}) && ${parentCondition}` : parentCondition;
        }
        // The child condition consists of the condition of this input and the parent condition ( which is the child condition of the parent input )
        const childCondition = condition;

        //The parent set condition is separated because it would become duplicate in grand children so this is only added to the condition ( that is not passed down to children )
        if (!isCustomCondition && parentSetConditionString) {
            condition = condition ? `${parentSetConditionString} && (${condition})` : parentSetConditionString;
        }

        return { condition, childCondition };
    };

    /**
     * Check if the interface setup has a gallery input with the isBackgroundImage prop
     * @param interfaceSetup The interface setup to check for asset gallery with the isBackgroundImage prop
     * @returns If the interface setup has a gallery input with the isBackgroundImage prop
     */
    static hasBackgroundImage(interfaceSetup: InterfaceSetupInput[] | DynamicLayerInput[]): InterfaceSetupInput | DynamicLayerInput | false {
        // Helper function to recursively search through the array and its children
        const searchIsBackgroundImage = (array: InterfaceSetupInput[] | DynamicLayerInput[]) => {
            for (const obj of array) {
                if (
                    ('isBackgroundImage' in obj && obj.isBackgroundImage) ||
                    (typeof obj.settings === 'object' && 'isBackgroundImage' in obj.settings && obj.settings?.isBackgroundImage)
                ) {
                    return obj;
                }
                // If the object has children, recursively search through the children
                if ('children' in obj && Array.isArray(obj.children)) {
                    if (searchIsBackgroundImage(obj.children)) {
                        return obj;
                    }
                }
            }
            return false;
        };

        // Start the search from the top-level array
        return searchIsBackgroundImage(interfaceSetup);
    }
}

export { InterfaceSetupHelpers };
